<template>
  <ul v-if="Object.keys(list || {}).length>0">
    <li v-for='(item, index) in Object.keys(list || {})' :key='index'>
      <div class='align-left date'><span style="margin-left:10px">{{ item }}</span></div>
      <van-row>
        <van-col :span='12'>
          <div class='item-box'>
            <div class='item-top'>
              <p class='item-title'>右眼</p>
              <p class='decimalRecord'>{{ (list[item][0].RightMax) }}</p>
              <p class='fivePointsRecord'>{{ (list[item][0].RightMin).toFixed(1) }}</p>
            </div>
            <div class='flex flex-align-items flex-justify-content-between item-bottom'>
              <p>正确 <span class='right-count'>{{ list[item][0].RightCorrectCount || 0 }}</span></p>
              <p>错误 <span class='wrong-count'>{{ list[item][0].RightErrorCount || 0}}</span></p>
            </div>
          </div>
        </van-col>
        <van-col :span='12'>
          <div class='item-box'>
            <div class='item-top'>
              <p class='item-title'>左眼</p>
              <p class='decimalRecord'>{{ (list[item][0].LeftMax) }}</p>
              <p class='fivePointsRecord'>{{( list[item][0].LeftMin.toFixed(1)) }}</p>
            </div>
            <div class='flex flex-align-items flex-justify-content-between item-bottom'>
              <p>正确 <span class='right-count'>{{ list[item][0].LeftCorrectCount || 0}}</span></p>
              <p>错误 <span class='wrong-count'>{{ list[item][0].LeftErrorCount || 0}}</span></p>
            </div>
          </div>
          </van-col>
          
      </van-row>
    </li>
  </ul>
  <div v-else style="margin-top:200px">
    暂无检查数据
  </div>
</template>

<script>
import mixins_commonMethods from '../commonJs/commonMethods.js'
export default {
  mixins: [mixins_commonMethods],
  data() {
    return {
      tittle: '',
    }
  },
  props: ['list'],
  components: {
  },
  created() {
  },
  methods: {
  },
};
</script>


 
<style lang="less">
@import "../assets/less/index.less";

.date {
  margin-left: 6px;
  font-size: .5rem;
  position: relative;
  .mt(20);
  .mb(20);
  &::before {
    position: absolute;
    top: 4px;
    margin-left: 0px;
    content: '';
    border-left: 2px solid #1D7BF5;
    height: 12px;
  }
}

.item-box {
  background: #fff;
  .b-radius(10);
  width: 76%;
  margin: 0 auto;
  
  .padding(30, 30, 30, 30);

  .item-title {
    .fs(35);
    .align-left;
    .lh(70);
  }

  .item-top {
    .border-dashed(3, #ddd);
    .padding(20, 20, 20, 20);
    .mb(30);
  }

  .decimalRecord {
    color: #0090FF;
    .fs(70);
    .align-left;
    .lh(100);
    font-weight: bold
  }

  .fivePointsRecord {
    .fs(40);
    color: #0090FF;
    .align-left;
    .lh(70);
  }

  .item-bottom {
    color: #979797;
    .fs(30);
    .mb(20);
  }

  .right-count {
    color: #979797;
  }

  .wrong-count {
    color: #FF5700;
  }

}
</style>