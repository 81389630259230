<template>
  <div class="content-box vision-box">
    <div class='page-content'>
      <div class='vision-box-content'>
        <ul class='flex'>
          <li class='examine-item' :class="ins == 0 ? 'currentItem' : ''" @click='ins = 0'>视力检查</li>
          <li class='examine-item' :class="ins == 1 ? 'currentItem' : ''" @click='ins = 1'>老花视力</li>
          <li class='examine-item' :class="ins == 2 ? 'currentItem' : ''" @click='ins = 2'>眼底黄斑</li>
        </ul>
        <history-item v-if="ins != 2" :list='list[ins]'></history-item>
        <div v-if="ins == 2">
          <div v-if="Object.keys(dataList).length > 0">
            <div v-for="item, index in Object.keys(dataList)" class="list-item" :key="index"
              @click="goItem(dataList[item], item)">
              <div style="width:50px"><img src="@/assets/imgs/hbleft.png" /></div>
              <div class="content">
                <div class="title">
                  黄斑检测
                </div>
                <div style="color:#979797;font-size: .35rem;">
                  检测时间：{{ item }}
                </div>
              </div>
              <div>
                <van-icon name="arrow" />
              </div>
            </div>
          </div>
          <div v-else style="margin-top:200px">
            暂无检查数据
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vHeader from '../components/header.vue'
import historyItem from '../components/historyItem.vue'
import mixins_commonMethods from '../commonJs/commonMethods.js'
export default {
  mixins: [mixins_commonMethods],
  data() {
    return {
      tittle: '',
      ins: 0,
      dataList: {},
      list: [
      ],
    }
  },
  components: {
    vHeader, historyItem
  },
  created() {
    this.$vlf.setItem('userName',this.$route.query.name)
    console.log('this.$route.query.userid ',this.$route.query.userid)
    // let userid = await this.$vlf.getItem('userId');
    this.$httpProxy.get('/api/visual/get', { Id: this.$route.query.userid }).then((res) => {
      if (res.data.Success) {
        this.list = [res.data.ResData.Js, res.data.ResData.Ys]
        this.dataList = res.data.ResData.Hb
      } else {
        this.$message.warning('出错了')
      }

    })
  },
  methods: {
    goItem(item, date) {
      this.$vlf.setItem('currentHBItem', item).then(res => {
        this.$router.push({ path: '/historyItem', query: { userId: this.$route.query.userId, date: date,h:true } })
      });
    },
  },
};
</script>


 
<style lang="less">
@import "../assets/less/vision.less";

.list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 6px;
  padding: 8px;
  height: 50px;

  .content {
    display: flex;
    flex-direction: column;
    text-align: left;
    flex: 1;
    margin-left: 6px;
    justify-content: space-between;
    height: 40px;

    .title {
      text-align: left;
      // font-weight: bold;
      font-size: .4rem;
      // margin: 3px auto;
    }
  }

}

.examine-item {
  color: #000;
  .mt(40);
  color: #000;
  .fs(35);
  .padding(10, 20, 10, 20);
  .mb(40);
}

.currentItem {
  .fs(37);
  border-bottom: 3px solid #1D99F9;
}
</style>